import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT
} from "react-admin";

const authProvider = theme => (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username } = params;
    localStorage.setItem("username", username);
    localStorage.setItem("team", username);
    localStorage.setItem("role", username);
    // accept all username/password combinations
    return Promise.resolve();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("username");
    localStorage.removeItem("team");
    localStorage.removeItem("role");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      localStorage.removeItem("team");
      localStorage.removeItem("role");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("username") &&
      localStorage.getItem("role") &&
      localStorage.getItem("team")
      ? Promise.resolve()
      : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject("Unknown method");
};

export default authProvider;
