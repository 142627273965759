// import { changeTheme } from "./actions";
import Icon from "@material-ui/icons/People";
import * as React from "react";
import {
  Create,
  Datagrid,
  DisabledInput,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";

export const TeamIcon = Icon;

export const TeamList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      {/* <TextField source="id" />
      <TextField source="team.name" />
      <DateField source="published_at" />
      <TextField source="average_note" />
      <TextField source="views" /> */}
      <EditButton basePath="/teams" />
    </Datagrid>
  </List>
);

const TeamTitle = ({ record }) => {
  return <span>Team {record ? `"${record.name}"` : ""}</span>;
};

const mapStateToProps = state => ({
  theme: state.theme
});

// export const TeamEdit = connect(
//   mapStateToProps,
//   {
//     changeTheme
//   }
// )(({ changeTheme, ...props }) => (
// ));

export const TeamEdit = props => (
  <Edit title={<TeamTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="name" />
      {/* <Button>Change Theme</Button> */}
      {/* onClick={() => changeTheme("dark")} */}
      {/*  */}
      {/*  */}
      {/* <TextInput source="teaser" options={{ multiLine: true }} />
      <LongTextInput source="body" />
      <DateInput label="Publication date" source="published_at" />
      <TextInput source="average_note" />
      <DisabledInput label="Nb views" source="views" /> */}
    </SimpleForm>
  </Edit>
);

export const TeamCreate = props => (
  <Create title="Create a Team" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      {/* <TextInput source="teaser" options={{ multiLine: true }} />
      <LongTextInput source="body" />
      <TextInput label="Publication date" source="published_at" />
      <TextInput source="average_note" /> */}
    </SimpleForm>
  </Create>
);
