import { createMuiTheme } from "@material-ui/core";
import { useObservable, useObserver } from "mobx-react-lite";
import hasuraDataProvider from "ra-data-hasura";
import * as React from "react";
import { Admin, Login, Resource } from "react-admin";
import { render } from "react-dom";
import authProvider from "./auth_provider";
import { TeamCreate, TeamEdit, TeamIcon, TeamList } from "./teams";

// const themeReducer = (previousState = "light", { type, payload }) => {
//   if (type === CHANGE_THEME) {
//     return payload;
//   }
//   return previousState;
// };

const darkPixel =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk+w8AASEBD503vxoAAAAASUVORK5CYII=";

const LoginPage = () => <Login backgroundImage={darkPixel} />;

const headers = {
  "content-type": "application/json",
  "x-hasura-access-key": process.env.HASURA_PASSWORD
};

const App = () => {
  const theme = useObservable({
    palette: {
      primary: {
        main: "#3A6E87" // 'curious blue'
      },
      secondary: {
        main: "#3A6E87" // orange
      }
    }
  });

  return useObserver(() => (
    <Admin
      authProvider={authProvider(theme)}
      // customReducers={{ theme: themeReducer }}
      dataProvider={hasuraDataProvider(process.env.HASURA_URL, headers)}
      locale="en"
      loginPage={LoginPage}
      theme={createMuiTheme(theme)}
      title="PlanX Admin"
    >
      <Resource
        name="teams"
        list={TeamList}
        edit={TeamEdit}
        create={TeamCreate}
        icon={TeamIcon}
      />
      {/* {permissions => [
        <Resource
          name="flows"
          list={FlowList}
          edit={FlowEdit}
          create={FlowCreate}
          icon={FlowIcon}
        />,
        permissions === "admin" ? (
          <Resource
            name="teams"
            list={TeamList}
            edit={TeamEdit}
            create={TeamCreate}
            icon={TeamIcon}
          />
        ) : null,
        <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          icon={UserIcon}
        />
      ]} */}
    </Admin>
  ));
};

render(<App />, document.getElementById("app"));
